import React from 'react'
import parse from 'html-react-parser';

const Hero = ({ title, title2, video, location }) => {
  return(
    <div className="project--hero-container grid" data-scroll-section>
      <div className="titles">
        <div>
          <h2 data-scroll data-scroll-speed="1" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{title}&nbsp;</h2>
          <h2 data-scroll data-scroll-speed="1" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{title2}</h2>
        </div>
        <p data-scroll data-scroll-speed="2" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{location}</p>
      </div>
      <div className="video-container">
        {parse(video)}
      </div>
    </div>
  )
}

export default Hero
