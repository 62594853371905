import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const Controls = ({ next }) => {
  const { t, i18n } = useTranslation('project')

  return(
    <div className="controls grid" data-scroll-section>
      <div data-scroll-speed="2" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <p >{t('next')}</p>
        <Link to={`/${i18n.language}/portfolio/${next.slug.current}`}><h2>{`${next.title} ${next.title2}`}</h2></Link>
      </div>
    </div>
  )
}

export default Controls
